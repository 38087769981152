@mixin shadow($prop) {
    -webkit-box-shadow: $prop!important;
    -moz-box-shadow: $prop!important;
    box-shadow: $prop!important;
}

@mixin radius($prop) {
    -webkit-border-radius: $prop!important;
    -moz-border-radius: $prop!important;
    border-radius: $prop!important;
}
@mixin radiusTopLeft($prop) {
  -webkit-border-top-left-radius: $prop!important;
  -moz-border-radius-topleft: $prop!important;
  border-top-left-radius: $prop!important;
}
@mixin radiusTopRight($prop) {
  -webkit-border-top-right-radius: $prop!important;
  -moz-border-radius-topright: $prop!important;
  border-top-right-radius: $prop!important;
}
@mixin radiusBottomLeft($prop) {
  -webkit-border-bottom-left-radius: $prop!important;
  -moz-border-radius-bottomleft: $prop!important;
  border-bottom-left-radius: $prop!important;
}
@mixin radiusBottomRight($prop) {
  -webkit-border-bottom-right-radius: $prop!important;
  -moz-border-radius-bottomright: $prop!important;
  border-bottom-right-radius: $prop!important;
}


@mixin breakpoint($class) {
    @if $class == xxs {
      @media (max-width: 480px) { @content; }
    }
    @else if $class == xs {
      @media (max-width: 767px) { @content; }
    }
    @else if $class == sm {
      @media (max-width: 767px) { @content; }
    }
    @else if $class == md {
      @media (max-width: 1024) { @content; }
    }
    @else if $class == lg {
      @media (max-width: 1400px) { @content; }
    }
    @else if $class == xl {
      @media (min-width: 1440px) { @content; }
    }
    @else if $class == mh {
      @media (max-device-height: 900px) { @content; }
    }
    @else if $class == lh {
      @media (max-device-height: 800px) { @content; }
    }
    @else if $class == xlh {
      @media (max-device-height: 700px) { @content; }
    }
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg. You entered "+ $class;
    }
  }
