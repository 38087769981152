@import "mixins.scss";

/* Font Family Import */
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');

:root {
    /* Font Families */
    --font-family:  'Rubik', sans-serif;
    --font-light:   300;
    --font-regular: 400;
    --font-medium:  500;
    --font-bold:    700;

    /* Font Sizes */
    --h1-size:              42px;
    --h2-size:              38px;
    --h3-size:              34px;
    --h4-size:              28px;
    --h5-size:              24px;
    --f-body-size:          20px;
    --f-cap-head-size:      16px;
    --f-cap-sub-size:       14px;
    --f-btn-size:           15px;

    /* xs = For devices smaller than an iPad */
    --h1-size-xs:           32px;
    --h2-size-xs:           28px;
    --h3-size-xs:           24px;
    --h4-size-xs:           20px;
    --h5-size-xs:           20px;

    /* xxs = For devices with very small screens, like an iPhone 5 */
    --h1-size-xxs:          26px;
    --h2-size-xxs:          20px;
    --h3-size-xxs:          18px;
    --h4-size-xxs:          16px;
    --h5-size-xxs:          16px;
    --f-body-size-xxs:      16px;
    --f-cap-head-size-xxs:  12px;
    --f-cap-sub-size-xxs:   12px;
    --f-btn-size-xxs:       12px;

    /* Font Weights */
    --h1-weight:            var(--font-bold);
    --h2-weight:            var(--font-bold);
    --h3-weight:            var(--font-medium);
    --h4-weight:            var(--font-medium);
    --h5-weight:            var(--font-medium);
    --f-body-weight:        var(--font-regular);
    --f-cap-head-weight:    var(--font-medium);
    --f-cap-sub-weight:     var(--font-regular);
    --f-btn-weight:         var(--font-medium);

    /* Font Colors */
    --h1-color:             var(--grey-darkest);
    --h2-color:             var(--grey-darkest);
    --h3-color:             var(--grey-darkest);
    --h4-color:             var(--grey-darkest);
    --h5-color:             var(--grey-darkest);
    --f-body-color:         var(--grey-darker);
    --f-cap-head-color:     var(--grey-darker);
    --f-cap-sub-color:      var(--grey-darker);
    --f-btn-color-lite:     white;
    --f-btn-color-dark:     var(--grey-darker);

    /* Line Spacing */
    --f-body-space:         30px;
    --f-cap-head-space:     20px;
    --f-cap-sub-space:      16px;

    /* xxs - For devices with really small screens, like an iPhone 5 */
    --f-body-space-xxs:         24px;
    --f-cap-head-space-xxs:     16px;
    --f-cap-sub-space-xxs:      12px;

    /* Global Header Font Styles */
    h1 {
        font-size:      var(--h1-size)!important;
        font-weight:    var(--h1-weight)!important;
        /* color:          var(--h1-color); */
        @include breakpoint(xs){
            font-size: var(--h1-size-xs)!important;
        }
        @include breakpoint(xxs){
            font-size: var(--h1-size-xxs)!important;
        }
    }
    h2 {
        font-size:      var(--h2-size)!important;
        font-weight:    var(--h2-weight)!important;
        color:          inherit;
        @include breakpoint(xs){
            font-size: var(--h2-size-xs)!important;
        }
        @include breakpoint(xs){
            font-size: var(--h2-size-xxs)!important;
        }
    }
    h3 {
        font-size:      var(--h3-size)!important;
        font-weight:    var(--h3-weight)!important;
        color:          inherit;
        @include breakpoint(xs){
            font-size: var(--h3-size-xs)!important;
        }
        @include breakpoint(xs){
            font-size: var(--h3-size-xxs)!important;
        }
    }
    h4 {
        font-size:      var(--h4-size)!important;
        font-weight:    var(--h4-weight)!important;
        color:          inherit;
        @include breakpoint(xs){
            font-size: var(--h4-size-xs)!important;
        }
        @include breakpoint(xxs){
            font-size: var(--h4-size-xxs)!important;
        }
    }
    h5 {
        font-size:      var(--h5-size)!important;
        font-weight:    var(--h5-weight)!important;
        color:          inherit;
        @include breakpoint(xs){
            font-size: var(--h5-size-xs)!important;
        }
        @include breakpoint(xxs){
            font-size: var(--h5-size-xxs)!important;
        }
    }

    /* Global Body Font Styles */
    p {
        font-size:      var(--f-body-size)! important;
        font-weight:    var(--f-body-weight)!important;
        /* color:          var(--f-body-color)!important; */
        line-height:    var(--f-body-space)!important;
        letter-spacing: normal;
        @include breakpoint(xs){
            font-size: var(--f-body-size-xxs)!important;
            line-height:    var(--f-body-space-xxs)!important;
        }
    }
    .caption-header {
        font-size:      var(--f-cap-head-size)!important;
        font-weight:    var(--f-cap-head-weight)!important;
        color:          var(--f-cap-head-color)!important;
        line-height:    var(--fi-cap-head-space)!important;
        @include breakpoint(xs){
            font-size: var(--f-cap-head-size-xxs)!important;
            line-height:    var(--fi-cap-head-space-xxs)!important;
        }
    }
    .caption-subheader {
        font-size:      var(--f-cap-sub-size)!important;
        font-weight:    var(--f-cap-sub-weight)!important;
        color:          var(--f-cap-sub-color)!important;
        line-height:    var(--fi-cap-sub-space)!important;
        @include breakpoint(xs){
            font-size: var(--f-cap-sub-size-xxs)!important;
            line-height:    var(--fi-cap-sub-space-xxs)!important;
        }
    }
}
