:root {
    /* Primary Color */
    --primary-color-lite:   #CE4132;
    --primary-color-dark:   #C0392B;
    --primary-color-darker: #972418;

    /* Secondary Color */
    --secondary-color-lite: #2b89b8;
    --secondary-color-med:  #135f85;
    --secondary-color-dark: #093A53;

    /* Greys */
    --grey-lite:    #f3f3f3;
    --grey:         #DADADA;
    --grey-med:     #CBCBCB;
    --grey-dark:    #797979;
    --grey-darker:  #515151;
    --grey-darkest: #2E2E2E;

    /* Other */
    --color-success:    #29af5e;
}
