@import "colors.scss";
@import "typography.scss";
@import "mixins.scss";

/* VARIABLES */
:root {
    /* Shadows */
    --shadow-sm: 0px 2px 5px 0px rgba(0,0,0,0.16);
    --shadow-md: 1px 3px 10px 0px rgba(0,0,0,0.18);
    --shadow-lg: 2px 5px 14px 0px rgba(0,0,0,0.22);

    /* Corner Radius */
    --radius-xs: 2px;
    --radius-sm: 4px;
    --radius-md: 8px;
    --radius-lg: 14px;
    --radius-xl: 20px;
}

/* Global Styles */
.md-form input[type=text]:focus:not([readonly]), .md-form input[type=password]:focus:not([readonly]), .md-form input[type=email]:focus:not([readonly]), .md-form input[type=url]:focus:not([readonly]), .md-form input[type=time]:focus:not([readonly]), .md-form input[type=date]:focus:not([readonly]), .md-form input[type=datetime-local]:focus:not([readonly]), .md-form input[type=tel]:focus:not([readonly]), .md-form input[type=number]:focus:not([readonly]), .md-form input[type=search-md]:focus:not([readonly]), .md-form input[type=search]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom:2px solid var(--secondary-color-med);
    @include shadow(0 0 0 0 var(--grey-dark));
}
.md-form .active {
    color: var(--grey-darkest)!important;
}
html, body {
    font-family: var(--font-family);
}
a {
    color: var(--grey-darkest);
    font-weight: var(--font-regular);
}

/* Global Button Styles */
.btn {
    @include shadow(var(--shadow-md));
    @include radius(var(--radius-sm));
    font-size: var(--f-btn-size)!important;
    font-weight: var(--f-btn-weight);
    padding:.8rem 2rem;
    margin: 5px 0 !important;
    width:320px;
    max-width:100%;
    letter-spacing:.8px;
    cursor:pointer;
    @include breakpoint(xxs){
        font-size: var(--f-btn-size-xxs)!important;
    }
}
.primary-btn {
    background: var(--primary-color-dark);
    border: 2px solid var(--primary-color-dark)!important;
    color: var(--f-btn-color-lite)!important;
}
.primary-btn:hover {
    background: var(--primary-color-lite);
    border: 2px solid var(--primary-color-lite)!important;
}
.secondary-btn {
    background: white;
    color: var(--primary-color-dark)!important;
    border: white !important;
  }

.secondary-btn:hover {
    background: var(--primary-color-dark);
    border: 2px solid var(--primary-color-dark)!important;
    color: white !important;
}
.grey-btn {
    background: var(--grey-med);
    color: var(--f-btn-color-dark)!important;
}
.grey-btn:hover {
    background: var(--grey-lite);
}
.white-btn {
    background:white;
    color: var(--f-btn-color-dark)!important;
}
.white-btn:hover {
    background: var(--grey-lite);
}
.info, .fa-info.fas {
    background: var(--primary-color-dark);
    width:26px;
    height:26px;
    line-height:26px;
    text-align:center;
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    border-radius:50px;
    color:white;
    font-size: .9em;
    cursor:pointer;
}
.info:hover, .fa-info.fas:hover {
    background: var(--primary-color-lite);
}

/* Global Background Styles */
.primary-bg-color {
    background: var(--secondary-color-dark);
    color: white;
}
.secondary-bg-color {
    background: var(--secondary-color-lite);
    color: white
}
.grey-bg {
    background: var(--grey-lite);
    color: var(--grey-darkets);
}

/* Header & Navigation */
.logo {
    height:25px;
    @include breakpoint(xs){
        height:20px;
    }
}
.navbar{
    background:white;
}
.sidenav a:hover {
    color: var(--primary-color-dark);
}

/* Footer */
footer {
    background: var(--grey-lite);
}
footer a {
    color: var(--grey-darkest)!important;
}
footer a:hover {
    color: var(--primary-color-dark)!important;
    padding-left:3px;
}

/* Slick Carousel */
.slick-dots li button:before {
    color: var(--primary-color-dark)!important;
}
.slick-dots li.slick-active button:before {
    color: var(--primary-color-dark);
}

/* Modal Popup */
.modal-footer {
    background:var(--grey-lite);
}

/* MDB Card */


/* Tabs & Pills */
.modal .md-pills .nav-link.active {
    background-color: var(--primary-color-dark)!important;
    color: white!important;
}
.modal .md-pills .nav-link {
    background-color:  var(--grey-lite)!important;
    color: var(--grey-dark)!important;
    border-bottom:5px solid var(--primary-color-dark);
}
.modal .md-pills li {
    padding:0!important;
}
/* Plans Hero Section Tabs */
.plans-hero-section .md-tabs .nav-item.open .nav-link, .md-tabs .nav-link.active {
    color:white!important
}
.plans-hero-section .md-tabs {
    background: var(--grey);
    @include radiusTopLeft(9px);
    @include radiusTopRight(9px);
}
.plans-hero-section .md-tabs .nav-item a:hover {
    background: var(--primary-color-lite);
    color:white;
}
.plans-hero-section .md-tabs .nav-item.open .nav-link, .plans-hero-section .md-tabs .nav-link.active {
    background-color: var(--primary-color-dark)!important;
    color:white;
}
.plans-hero-section .md-tabs .nav-item a {
    color: rgba(0,0,0,.4);
    text-transform: uppercase;
}
.plans-hero-section .md-pills .nav-link {
    text-align:left;
}
.plans-hero-section .md-pills .nav-link.active {
    background: var(--primary-color-dark);
}
.plans-hero-section mdb-tab .tab-container .tab-content {
    border-left:5px solid var(--primary-color-dark);
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    margin-left:-5px;
}
.plans-hero-section .tab-container .nav-stacked {
    border-right:5px solid var(--primary-color-dark);
}

/* Plans Customization - Addons */
.addon-btn {
    border:2px solid var(--grey)!important;
    @include shadow(none);
    border-color: var(--grey)!important;
}
.addon-btn:hover {
    color:white;
    border: 2px solid var(--secondary-color-dark)!important;
    background: var(--secondary-color-dark);
}
.addon-btn .selected {
    color:white;
    border: 2px solid var(--secondary-color-dark)!important;
    background: var(--secondary-color-dark);
}
