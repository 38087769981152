/********************
IMPORTS
********************/
@import "assets/scss/mixins.scss";

:root {
  /* Primary Color */
  --primary-color-lite: #FFCC00;
  --primary-color-dark: #FFCC00; // hover
  --primary-color-darker: #FFCC00; // main color
  --primary-color-darkest: #FFCC00; // main color

  /* Secondary Color */
  --secondary-color-lite: #202020;
  --secondary-color-med: #202020;
  --secondary-color-dark: #000000;

  /* Greys */
  --grey-super-lite: #ffffff;
  --grey-lite: #D0D0CE;
  --grey: #97999B;
  --grey-med: #97999B;
  --grey-dark: #4C4A44;
  --grey-darker: #202020; // not set
  --grey-darkest: #000000; // not set

  /* Other */
  --color-success: #0a7305;
  --color-info: #1960d0;
  --color-error: #CC0000;

  --font-black: #000000;
  --font-white: #ffffff;
}

/* Font Family Import */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body,
html {
  background-color: var(--grey-super-lite);

  @media (max-width: 767px) {
    background-color: white;
  }
}

:root {
  /* Font Families */
  --font-family: "Roboto", Arial, Helvetica, sans-serif;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* Font Sizes */
  --h1-size: 59px;
  --h2-size: 29px;
  --h3-size: 22px;
  --h4-size: 16px;
  --h5-size: 14px;
  --f-body-size: 16px;
  --f-cap-head-size: 15px;
  --f-cap-sub-size: 14px;
  --f-btn-size: 15px;

  // xs = For devices smaller than an iPad
  --h1-size-xs: 40px;
  --h2-size-xs: 29px;
  --h3-size-xs: 22px;
  --h4-size-xs: 16px;
  --h5-size-xs: 15px;

  // xxs = For devices with very small screens, like an iPhone 5
  --h1-size-xxs: 40px;
  --h2-size-xxs: 29px;
  --h3-size-xxs: 22px;
  --h4-size-xxs: 16px;
  --h5-size-xxs: 15px;
  --f-body-size-xxs: 16px;
  --f-cap-head-size-xxs: 12px;
  --f-cap-sub-size-xxs: 12px;
  --f-btn-size-xxs: 12px;

  /* Line Heights */
  --h1-lineheight: 57.23px;
  --h2-lineheight: 34.8px;
  --h3-lineheight: 31px;
  --h4-lineheight: 28px;
  --h5-lineheight: 28px;

  /* Line Heights xs devices */
  --h1-lineheight-xs: 43.2px;
  --h2-lineheight-xs: 34.8px;
  --h3-lineheight-xs: 31px;
  --h4-lineheight-xs: 28px;
  --h5-lineheight-xs: 28px;

  /* Line Heights xs devices */
  --h1-lineheight-xxs: 43.2px;
  --h2-lineheight-xxs: 34.8px;
  --h3-lineheight-xxs: 36.3px;
  --h4-lineheight-xxs: 28px;
  --h5-lineheight-xxs: 28px;

  /* Font Weights */
  --h1-weight: var(--font-bold);
  --h2-weight: var(--font-bold);
  --h3-weight: var(--font-medium);
  --h4-weight: var(--font-medium);
  --h5-weight: var(--font-medium);
  --f-body-weight: var(--font-light);
  --f-cap-head-weight: var(--font-medium);
  --f-cap-sub-weight: var(--font-regular);
  --f-btn-weight: var(--font-medium);

  /* Font Colors */
  --h1-color: var(--secondary-color-med);
  --h2-color: var(--secondary-color-med);
  --h3-color: var(--secondary-color-med);
  --h4-color: var(--secondary-color-med);
  --h5-color: var(--secondary-color-med);
  --f-body-color: var(--secondary-color-med);
  --f-cap-head-color: var(--secondary-color-med);
  --f-cap-sub-color: var(--secondary-color-med);
  --f-btn-color-lite: white;
  --f-btn-color-dark: var(--secondary-color-med);

  /* Line Spacing */
  --f-body-space: 28px;
  --f-cap-head-space: 20px;
  --f-cap-sub-space: 16px;

  // xxs - For devices with really small screens, like an iPhone 5
  --f-body-space-xxs: 24px;
  --f-cap-head-space-xxs: 16px;
  --f-cap-sub-space-xxs: 12px;

  .spinner-client-color {
    border-color: var(--primary-color-darker);
  }

  :focus-visible {
    outline: 2px solid var(--secondary-color-med) !important;
  }

  select:focus-visible {
    outline: 2px solid var(--secondary-color-med) !important;
    padding-right: 3px;
  }

  select {
    // background-color: var(--grey-lite) !important;
    height: 42px !important;
    font-weight: var(--font-bold);
  }

  input {
    font-weight: var(--font-bold);
    line-height: 25px;
  }

  body {
    color: var(--grey-darker);
    font-weight: var(--font-regular);
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: var(--f-body-size);
    line-height: 28px;
  }

  /* Global Header Font Styles */
  h1 {
    color: var(--font-black);
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: var(--h1-size) !important;
    font-weight: var(--h1-weight) !important;
    line-height: var(--h1-lineheight) !important;
    text-transform: uppercase;
    letter-spacing: normal;

    @include breakpoint(xs) {
      font-size: var(--h1-size-xs) !important;
      line-height: var(--h1-lineheight-xs) !important;
    }

    @include breakpoint(xxs) {
      font-size: var(--h1-size-xxs) !important;
      line-height: var(--h1-lineheight-xxs) !important;
    }
  }

  h2 {
    color: var(--grey-darker);
    font-size: var(--h2-size) !important;
    font-weight: var(--h2-weight) !important;
    line-height: var(--h2-lineheight) !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: normal;

    @include breakpoint(xs) {
      font-size: var(--h2-size-xs) !important;
      line-height: var(--h2-lineheight-xs) !important;
    }

    @include breakpoint(xs) {
      font-size: var(--h2-size-xxs) !important;
      line-height: var(--h2-lineheight-xxs) !important;
    }
  }

  h3 {
    color: var(--grey-dark);
    font-size: var(--h3-size) !important;
    font-weight: var(--h3-weight) !important;
    line-height: var(--h3-lineheight) !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: normal;

    @include breakpoint(xs) {
      font-size: var(--h3-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }

    @include breakpoint(xs) {
      font-size: var(--h3-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }

  }

  h4 {
    color: var(--font-black);
    font-size: var(--h4-size) !important;
    font-weight: var(--h4-weight) !important;
    line-height: var(--h4-lineheight) !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    text-transform: none;

    @include breakpoint(xs) {
      font-size: var(--h4-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }

    @include breakpoint(xxs) {
      font-size: var(--h4-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }
  }

  h5 {
    font-size: var(--h5-size) !important;
    font-weight: var(--h5-weight) !important;
    line-height: var(--h5-lineheight) !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    text-transform: none;

    @include breakpoint(xs) {
      font-size: var(--h5-size-xs) !important;
      line-height: var(--h5-lineheight-xs) !important;
    }

    @include breakpoint(xxs) {
      font-size: var(--h5-size-xxs) !important;
      line-height: var(--h5-lineheight-xxs) !important;
    }
  }

  /* Global Body Font Styles */

  a {
    color: var(--grey-darker);

    &:hover {
      color: var(--grey-darker);
    }
  }



  .superscript,
  .cap-head .superscript {
    font-weight: 300 !important;
    font-size: 0.7em !important;
  }

  .bs-tooltip-top .arrow:before {
    border-top-color: var(--grey-darker) !important;
  }

  .bs-tooltip-right .arrow:before {
    border-right-color: var(--grey-darker) !important;
  }

  .bs-tooltip-bottom .arrow:before {
    border-bottom-color: var(--grey-darker) !important;
  }

  .bs-tooltip-left .arrow::before {
    border-left-color: var(--grey-darker) !important;
  }

  mdb-tooltip-container {
    .tooltip-inner {
      background: var(--grey-darker) !important;
      max-width: 350px !important;
      min-width: 300px !important;
      color: var(--grey-lite) !important;
      padding: 0.75rem !important;
      font-size: 14px !important;
    }
  }

  p {
    font-size: var(--f-body-size) !important;
    font-weight: var(--f-body-weight) !important;
    line-height: var(--f-body-space) !important;
    letter-spacing: normal;

    @include breakpoint(xs) {
      font-size: var(--f-body-size-xxs) !important;
      line-height: var(--f-body-space-xxs) !important;
    }
  }

  .caption-header {
    font-size: var(--f-cap-head-size) !important;
    font-weight: var(--f-cap-head-weight) !important;
    color: var(--f-cap-head-color) !important;
    line-height: var(--fi-cap-head-space) !important;

    @include breakpoint(xs) {
      font-size: var(--f-cap-head-size-xxs) !important;
      line-height: var(--fi-cap-head-space-xxs) !important;
    }
  }

  .caption-subheader {
    font-size: var(--f-cap-sub-size) !important;
    font-weight: var(--f-cap-sub-weight) !important;
    color: var(--f-cap-sub-color) !important;
    line-height: var(--fi-cap-sub-space) !important;

    @include breakpoint(xs) {
      font-size: var(--f-cap-sub-size-xxs) !important;
      line-height: var(--fi-cap-sub-space-xxs) !important;
    }
  }

  .vehicle_edit_btn {
    background-color: var(--primary-color-darker);
  }

  .image-border {
    color: var(--primary-color-darker) !important;
  }

  .plans-title-section {
    margin-top: -15rem;
    color: white;
    text-align: center;
    display: block;

    @include breakpoint(xs) {
      margin-top: -17rem;
    }

    @include breakpoint(xxs) {
      margin-top: -17rem;
    }

    padding-bottom: 3em;
  }

  .alert-warning {
    color: var(--color-error);
    border-color: var(--color-error);
    font-size: 13px;
  }

  .alert-info {
    color: var(--color-info);
    border-color: var(--color-info);
    font-size: 132px;
  }

  .error-message {
    color: var(--color-error) !important;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: none;
  }

  input.form-control.validate-error.ng-invalid.ng-touched {
    border-bottom: 1px solid var(--color-error);
  }

  .hiw-img {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 70px !important;
  }

  mdb-tabset ul.md-tabs.tabs-3 {
    li {
      height: 48px;
      line-height: 48px;

      a {
        padding-top: 10px;
        text-transform: uppercase;

        p {
          letter-spacing: 1px !important;
        }
      }
    }

    .nav-item p {
      padding: 0 !important;
    }
  }

  mdb-tabset .tab-pane i.fa-check {
    color: var(--primary-color-darker);
  }

  .navbar .nav-dealer {
    color: var(--grey-super-lite);
  }

  app-component-cta {
    a.btn {
      text-align: center !important;
    }
  }

  .fa-sign-out-alt {
    color: var(--grey-super-lite);
  }

  app-mobile-nav .fa-sign-out-alt {
    color: var(--grey-darker);
  }

  .btn {
    text-transform: uppercase;
    font-weight: var(--font-medium);
    font-size: 16px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 13px 0;

    &.primary-btn {
      background: var(--secondary-color-med) !important;
      border: 1px solid var(--secondary-color-med) !important;
      color: var(--font-white);

      &:hover {
        background: transparent !important;
        border: 1px solid var(--secondary-color-dark) !important;
        color: var(--secondary-color-dark) !important;
      }

      &:disabled {
        background-color: var(--grey) !important;
        border: 1px solid var(--grey) !important;
        color: white !important;
      }
    }

    &.secondary-btn {
      background-color: transparent !important;
      border: 1px solid var(--grey-darker) !important;
      color: var(--grey-darker) !important;

      &:hover,
      &.highlighted {
        background: transparent !important;
        border: 1px solid var(--grey-darkest) !important;
        color: var(--grey-darkest) !important;
      }

      &:disabled {
        background-color: var(--grey) !important;
        border: 1px solid var(--grey) !important;
        color: var(--font-white) !important;
      }
    }

    &.secondary-btn.plan-button {
      background-color: var(--primary-color-darker) !important;
      border: 1px var(--primary-color-darker) !important;
      color: var(--grey-darker) !important;

      &:hover,
      &.highlighted {
        background-color: var(--primary-color-darker) !important;
        border: 1px var(--primary-color-darker) !important;
        color: var(--grey-darker) !important;
      }

      &:disabled {
        background-color: var(--grey) !important;
        border: 1px solid var(--grey) !important;
        color: var(--font-white) !important;
      }
    }
  }

  .btn-round {
    border-radius: 50% !important;
    position: absolute;

    i {
      left: 13px;
      top: 13px;
      position: absolute;
    }
  }

  .progress-bar {
    background-color: var(--grey-darker);
  }


  .documents_table th {
    color: var(--grey-darker);
  }

  .ngx-file-drop__drop-zone {
    .ngx-file-drop__content {
      color: var(--grey-darker) !important;

      a {
        color: var(--grey-darker) !important;

        &:hover {
          color: var(--grey-darker) !important;
          text-decoration: underline;
        }
      }
    }
  }

  .plan-detail {
    color: var(--grey-darker) !important;
  }

  .mobile-plan-footer {
    .btn {
      &.primary-btn {
        color: var(--grey-lite) !important;
        border-color: var(--grey-lite) !important;
      }
    }

    background-color: var(--grey-darker) !important;

    .plan-name {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 20px;
      font-weight: normal !important;
      letter-spacing: 1px;
    }
  }

  footer .footer-copyright {
    background-color: var(--secondary-color-med);
    color: white;

    p {
      font-size: 11px !important;
      line-height: 21px !important;
    }

    a {
      color: white !important;
      font-size: 14px !important;
      width: 100px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .footer-demo-text {
    color: var(--grey-darker) !important;
  }

  .subfooter {
    .subfooter-item img {
      padding: 0 !important;
      height: 32px !important;
    }

    a:hover {
      color: #e71519;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1rem !important;
    }
  }

  .rgba-black-light,
  .rgba-black-light:after {
    background-color: rgba(0, 0, 0, 0);
  }


  /********************
    FOOTER
    ********************/


  footer>div {
    background-color: var(--secondary-color-med);
    margin-top: 0;
    width: 100%;
  }

  footer {
    .give_us_a_call {
      margin-top: 20px;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .footer-version {
      font-size: 12px;
    }
  }

  footer .footer-group-logo {
    max-width: 100%;
    width: 100%;
    text-align: center;
    flex: none;
    margin-top: 60px;

    .footer-logo {
      padding: 0 !important;

      img {
        width: 90px;

        @include breakpoint(xs) {
          width: 60px;
        }
      }
    }
  }

  footer .footer-group-contact-us {
    strong {
      display: none;
    }

    .list-unstyled {
      text-align: center !important;
      color: white;

      i.fas {
        color: white;
      }
    }
  }

  .hours-of-operation {
    color: var(--grey-lite) !important;
  }

  .checkout.nav-item h3 {
    color: var(--grey-super-lite);
    margin-bottom: 0 !important;
  }

  footer {
    background-color: var(--grey-darker);
    padding-top: 0;
  }


  /********************
    LEGAL PAGE
    ********************/

  app-legal-notice .legal,
  app-privacy .legal,
  app-accessibility .legal {
    h1 {
      padding: 10px 0 20px 0;
    }

    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }
  }

  /********************
    FAQ SLICE
    ********************/

  .faq-overview {
    margin-top: 10px;
    padding: 30px 0;

    .faq {
      padding: 0 20px;
    }

    .container {
      .faq-header {
        text-align: center;
        margin-bottom: 10px;
      }

      .card-header {

        h5,
        h4 {
          width: calc(100% - 32px);
        }
      }

      .card-body {
        p {
          font-size: 0.9rem !important;
          font-weight: 300 !important;
          line-height: 1.7 !important;
        }
      }
    }
  }

  .faq .mdb-accordion-indicator::after {
    color: var(--grey-dark);
  }

  app-slice-faq h2 {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    color: var(--grey-darker);
    font-weight: 500 !important;
  }

  mdb-accordion-item-head h3 {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    color: var(--grey-darker);
    font-weight: 500 !important;
    text-transform: none !important;
  }

  /********************
    PRODUCT PLAN PAGE
    ********************/
  .coverage-start-date {
    color: var(--grey-darker) !important;
  }

  .click-text {
    font-weight: var(--font-bold);
    color: var(--grey-darker) !important;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
    // white-space: nowrap;
    // padding-bottom: 40px;
  }

  .plans-hero-section .tab-container .tab-pane ul li .nav-link:hover {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lowest-term-price h3 {
    color: var(--font-black) !important;
  }

  app-product-plans app-slice-plans-hero {
    h1 {
      color: white;
    }

    .col-md-3 {
      background-color: var(--grey-lite);
    }

    ul.md-pills li a {
      span {
        text-transform: uppercase;

        letter-spacing: 1px;
        font-size: 14px;

        img {
          display: none;
        }
      }

      &.nav-link.active {
        background: white;
        border-radius: 0 !important;
        color: var(--primary-color-darker);
        border-bottom: 1px solid var(--primary-color-darker);

        img {
          float: right;
          display: block;
        }
      }
    }

    .plans-hero-section .tab-container .nav-stacked {
      border-right: none;
    }

    .plans-hero-section mdb-tab .tab-container .tab-content {
      border-left: none;
    }

    .plans-hero-section .tab-container .tab-pane ul li .nav-link {
      -moz-border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      border-radius: 0 !important;
    }
  }

  .customize-plan-wrap {
    .dollarsign {
      font-size: 15px;
      line-height: 20px;
      color: #333;
    }

    .prices {
      color: white;
      background: var(--grey-dark);
      border-radius: 5px !important;
    }
  }

  .switch label input[type="checkbox"]:checked+.lever:after {
    background-color: var(--primary-color-dark);
  }

  .switch label input[type="checkbox"]:checked+.lever {
    background-color: var(--primary-color-dark);
  }

  mdb-select>div>div.single,
  mdb-select.validate-success.ng-valid.ng-touched div.single,
  mdb-select.validate-error.ng-invalid.ng-touched div.single {
    border-bottom: none !important;
    box-shadow: none !important;
    padding-left: 15px;

    div.value {
      color: #333333;
    }
  }

  .mdb-select-toggle {
    right: 15px;
  }

  .mdb-select-toggle {
    margin-top: -0.5rem !important;
  }

  .prices .small {

    font-size: 14px;
    letter-spacing: 1px;
  }

  .mdb-select-toggle {
    color: var(--grey-darker);
  }

  .md-form mdb-select+label {
    padding-left: 5px;
    transform: translateY(-2px);
    transform: translateY(-34px); // to keep label at the top

    &.active {
      transform: translateY(-34px);
    }
  }

  .md-form label {
    transform: translateY(10px);
    transform: translateY(-28px) scale(0.8); // to keep label at the top
    padding-left: 5px;
    color: var(--grey-darker) !important;

    &.active {
      transform: translateY(-28px) scale(0.8);
    }

    i {
      color: var(--grey-med) !important;
      font-size: 12px;
    }

    app-tooltip {
      transform: translateY(0) scale(1.25);
      display: inline-block;
    }
  }

  .editBilling .md-form label,
  .editContact .md-form label,
  .editPayment .md-form label {
    transform: translateY(-24px) scale(0.8) !important; // to keep label at the top
    padding-left: 0px;
    color: var(--grey-darker) !important;

    &.active {
      transform: translateY(-24px) scale(0.8) !important;
      font-size: 1rem !important;
    }

    i {
      color: #212529;
    }
  }

  .switch.success-switch label {
    color: var(--grey-darker) !important;
    font-size: 13px;
    margin-bottom: 0rem;
    font-weight: var(--font-medium);

    i {
      color: #212529 !important;
    }
  }

  .deductible-label {
    color: var(--grey-darker) !important;
    font-size: 15px;
    font-weight: bold;
  }

  .select-container .deductible-line {
    border-top: 1.5px solid var(--grey);
  }

  .btn.primary-btn.deductible-btn {
    // margin-left: -1px !important;
    font-size: 14px !important;
    color: var(--font-black) !important;
    letter-spacing: 0px;
    background: white !important;
    border-width: 1px !important;

    &:hover {
      background: white !important;
      color: var(--font-black) !important;
    }

    &.active {
      background: var(--grey-darker) !important;
      color: white !important;
      border-color: var(--grey-darker) !important;
    }
  }

  .md-form {
    // border: 1px solid var(--grey-med);
    box-shadow: 0 0 3px rgb(0 0 0 / 0.5);
    height: 42px;
  }

  input.form-control {
    width: calc(100% - 10px);
  }

  .customize-plan-wrap ul.stepper {
    .md-form label {
      transform: translateY(-14px) scale(0.8); // to keep label at the top
      padding-left: 5px;
      top: -18px;
    }

    .md-form label.active {
      transform: translateY(-14px) scale(0.8);
    }
  }

  mdb-select>div>div.single.focused {
    border: 1px solid var(--grey-med);
    box-shadow: 0 1px 0 0 var(--grey-med);
  }

  .mdb-select-toggle.focused {
    color: var(--grey-med);
  }

  // .md-form input[type="text"],
  // .md-form input[type="number"],
  // .md-form input[type="email"] {
  //   border: none !important;
  //   line-height: 18px;
  //   padding-left: 5px;
  //   font-size: 1.25em;
  //   padding-top: 0.5rem;
  // }
  input.form-control.validate-error.ng-invalid.ng-touched,
  input.form-control.validate-success.ng-valid.ng-touched {
    border-bottom: none !important;
    box-shadow: none !important;
    border: none;
  }

  .md-form input:focus:not([readonly]) {
    border-bottom: none !important;
    box-shadow: none !important;
  }

  .customize-plan-wrap .card {
    border-radius: 5px !important;
  }

  .step-title>span {
    position: absolute;
    left: 5px;

    text-transform: uppercase;
    top: 25px;
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--grey-med);
  }

  ul.stepper.horizontal {

    // overflow: visible !important;
    .step-new-content {
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }

  @media only screen and (max-width: 420px) {
    .step-title>span {
      position: relative;
      top: 0;
      left: -8px;
    }
  }

  @media (max-width: 768px) {
    .container-margin {
      margin-top: 30px;
    }
  }

  .step.active .step-title>span {
    color: var(--primary-color-dark);
  }

  i.fa-question-circle,
  i.fa-info-circle {
    color: var(--grey-med) !important;
    cursor: pointer;
    font-size: 12px !important;
  }


  .form-check-input[type="checkbox"]:checked+label:before {
    border-color: transparent var(--grey-darker) var(--grey-darker) transparent;
  }

  .justify-content-between.tabbar>a {
    font-size: 14px;
    color: var(--grey-darker) !important;

    &.active {
      font-weight: var(--font-bold);
    }
  }

  .verify-vehicle-header strong {
    font-weight: var(--font-bold);
    font-size: 22px;
    text-transform: uppercase;
    color: var(--font-black);
    font-family: 'Roboto', 'Times New Roman', Times, serif;
  }

  .where-vin {
    top: -26px;
  }

  .balance-due {
    color: var(--grey-med);
    margin-top: 20px;
  }

  .blue-link {
    color: var(--grey-darker) !important;

    &:hover {
      color: var(--grey-dark) !important;
    }
  }

  app-my-account-verify {
    .verify-fields {
      height: 42px;
      max-width: 330px;
      margin: auto;
    }

    .md-form label {
      transform: translateY(-24px) scale(0.8);
    }

    &.active {
      transform: translateY(-24px) scale(0.8);
    }
  }


  .modal-content.modal-dynamic form .md-form.mt-3.mb-3 {
    margin-top: 30px !important;
  }

  .customize-plan-wrap .card {
    .terms-conditions {
      position: absolute;
      top: -30px;
      color: var(--primary-color-darker);
      text-transform: uppercase;
      text-align: center;
      left: 0;
      height: 30px;
      background-color: white;

      i {
        margin-right: 10px;
      }

      a {
        text-decoration: none !important;
      }
    }

    .terms-container {
      margin-top: 10px;
      line-height: 20px;

      .small {
        font-size: 12px;
      }
    }

    .payment-container {
      margin-top: 7px;
      line-height: 20px;

      .small {
        font-size: 12px;
      }
    }

    .due-today {
      font-size: 25px;
      font-weight: bold;
    }

    .btn-back {
      background: none;
      color: var(--primary-color-darkest) !important;
      box-shadow: none !important;
      border: none !important;

      &:hover {
        color: var(--primary-color-lite) !important;
      }

      &.mobile {
        color: white !important;
      }
    }
  }

  .card-body {
    padding: 0 16px 16px 16px !important;
  }

  @media (max-width: 768px) {
    .customize-plan-wrap .card {
      .prices {
        border-radius: 0 !important;
      }
    }
  }

  @media (min-width: 768px) {
    .card-body {
      padding: 32px !important;
    }
  }

  /********************
    CART PAGE
    ********************/

  .downpayment-dollarsign {
    position: absolute;
    left: 7px;
    top: 8px;
    font-size: 0.95rem;

    @media (min-width: 768px) {
      top: 9px;
    }
  }

  .downpayment-input {
    padding-left: 20px !important;
    width: calc(100% - 20px) !important;
  }

  .downpayment-input-form {
    box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
  }

  .payment-options-tabs {

    &.card-body {
      padding: 0px !important;
    }
  }

  .shopping-cart .card-header,
  .shopping-cart hr.fade-line {
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  app-shopping-cart,
  app-checkout {
    display: block;
    background-color: #f6f6f6;

    @media (max-width: 767px) {
      background-color: white;
    }
  }

  .shopping-cart {

    padding-top: 40px;

    .go-back-to-cart {
      font-weight: var(--font-bold);
      text-transform: uppercase;
      font-size: 13px;
    }

    .remaining-payments,
    .payment-plan {
      font-weight: var(--font-bold);
      font-size: 14px;
      color: var(--grey-med);
    }

    h2 {
      font-size: 20px !important;
    }

    h2 mdb-icon {
      display: none;
    }

    .md-form label {
      padding-left: 0px;
    }

    // a {
    //   text-decoration: none;
    //   font-size: 14px;
    //   text-align: center;
    //   text-transform: uppercase;
    //   letter-spacing: 1px;

    //   color: var(--primary-color-darker);

    //   &.click-text {
    //     color: var(--primary-color-darker);
    //   }

    //   @media (max-width: 900px) {
    //     font-size: 12px;

    //   }


    //   &.active {
    //     color: #041022;
    //   }

    //   mdb-icon {
    //     display: none;
    //   }
    //   &.desktop {
    //     font-size: 14px;
    //     color: var(--color-info);
    //   }
    // }

    .coverage-starts {
      text-transform: uppercase;
      font-weight: var(--font-bold);
      color: var(--grey-dark);
    }

    .continue-shopping {
      display: none;
    }

    .your-vehicle {
      padding-top: 0 !important;

    }
  }

  .mobile-product-name {
    letter-spacing: 1px;
    color: var(--primary-color-dark);
  }

  .shopping-cart {

    .product-name,
    .cart-price {
      color: var(--font-black);
      text-transform: uppercase;
      font-family: "Roboto", Arial, Helvetica, sans-serif;
    }

    .cart-item-body {
      padding-left: 0 !important;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--grey);
    }

    .card .card-body .card-text {
      color: var(--font-black);
    }

    .row {
      .media-body {
        border: 1px solid var(--grey);

        h5,
        .font-weight-bold,
        span strong {
          color: var(--font-black);
          text-transform: uppercase;
          font-family: "Roboto", Arial, Helvetica, sans-serif;
        }

        .col-4 {
          position: relative;
          right: 0;
        }
      }
    }

    .payment-button {
      border: 1px solid var(--grey-darker) !important;
      background-color: var(--grey-super-lite) !important;
      color: black !important;
      font-weight: var(--font-bold);
      font-size: 14px;

      &.active {
        border: 1px solid var(--grey-darker) !important;
        color: white !important;
        background-color: var(--grey-darker) !important;
      }
    }

    .fa-shopping-cart {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 30px;
    }
  }


  /********************
    SUPPORT PAGE
    ********************/

  .support .solid-hero {
    background: none;
    padding: 100px 0 0 0;

    h2 {
      text-align: center;
      padding: 10px;
    }

    button.primary-btn {
      i {
        color: white;
      }
    }

    button.primary-btn:hover {
      i {
        color: var(--grey-darkest);
      }
    }
  }

  .support .container:not(.support-items) {
    h1 {
      color: black !important;
    }

    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }

    p,
    li {
      font-size: 18px !important;
    }

    @include breakpoint(xxs) {

      p,
      li {
        font-size: 16px !important;
      }

      &.pl-5 {
        text-align: center;
        padding-left: 0 !important;
      }
    }
  }

  .support .container.support-items>.row {
    margin-top: 0 !important;
    padding-top: 1rem !important;

    mdb-card {
      // max-width: 312px;
      margin: auto;
      max-height: 350px;

      img {
        width: 30% !important;
      }

      h2 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      mdb-card-footer {
        padding-bottom: 15px;

        // a.btn {
        //   color: white !important;
        //   border: none;
        //   box-shadow: none !important;

        //   &:hover {
        //     color: var(--primary-color-darkest) !important;
        //   }
        // }
      }
    }
  }

  .modal-dialog {
    max-width: 575px;

    i.fa-arrow-right {
      display: none;
    }

    .change-vehicle {
      text-align: center;

      color: var(--grey-dark);
      font-size: 14px;
      width: 100%;

      a {

        color: var(--color-info);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }

    .cancel-btn {
      box-shadow: none !important;
      color: var(--primary-color-dark);
      white-space: nowrap;
    }

    .primary-btn {
      white-space: nowrap;
    }
  }

  .form-control {
    border: none !important;
    font-size: 1rem;
    padding: 8px 5px;
    margin-bottom: 8px !important;
  }

  @media (min-width: 1200px) {
    .col-xl-4 {
      flex: none;
      max-width: 100%;
    }
  }

  .btn.disabled,
  .btn:disabled {
    background-color: var(--grey-dark) !important;
    border: 1px solid var(--grey-darker) !important;
    color: var(--grey-darker) !important;
  }

  .product-footer {
    background-color: #f9f9f9;
  }

  .product-footer-text {

    font-size: 12px;
    color: var(--color-info);
    line-height: 16px;
  }

  .card-border {
    border-right: 1px solid var(--grey);
  }

  .overline {
    font-weight: var(--font-bold);
    color: var(--grey-med);
    font-size: 14px;
  }

  /********************
    VERIFY ACCOUNT
    ********************/

  .verify-account {
    .account-header {
      color: var(--font-black);
      font-size: var(--h2-size) !important;
      font-weight: var(--font-regular) !important;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      letter-spacing: 1px;
    }

    .tab-content .col-12 {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    mdb-tabset>.container-fluid>.row {
      max-width: 430px;
      margin: auto;
    }

    mdb-card.col-md-8.card {
      max-width: 684px;
    }

    p {
      color: var(--secondary-color-med);
    }

    .container-fluid>.row>.col-md-12:first-child {
      padding: 0 3rem;
    }

    ul.md-pills {
      border-bottom: 3px solid var(--grey-darker);
      border-radius: 0;
    }
  }

  .verify-account {
    margin-top: 50px;

    .card {
      box-shadow: none;
      background-color: transparent;

      input {
        background: white;
      }
    }
  }

  .verify-account .md-pills {
    li {
      padding: 0;
      margin-left: 3px;
      margin-bottom: 2px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        margin-bottom: -2px;
        margin-top: -2px;
      }
    }

    .nav-link {
      transition: none;
      padding: 5px 1rem;
      border: 1px solid var(--primary-color-darkest);
      letter-spacing: 1px;
      font-size: 16px;
      box-shadow: none !important;
      border-radius: 0 !important;
      color: var(--primary-color-darkest);

      &:hover {
        background-color: transparent;
        color: var(--primary-color-darkest);
      }

      &.active {
        background-color: var(--grey-darker);
        border: 1px solid var(--grey-darker);
        color: white;
        padding-top: 8px;
      }

      i.fas {
        display: none;
      }
    }
  }

  /********************
  MY ACCOUNT
  ********************/

  .account-container {
    .cc-update-section {
      padding-left: 2px;
    }

    .account-heading {
      background-color: white;
    }

    .vehicle-tab {
      .tab-top {
        padding-top: 15px;
        // background-color: transparent;
        border-bottom: none;

        .tab-title {
          color: var(--grey-darker);
          font-weight: var(--font-bold);
        }

        .tab-title-info {
          color: var(--grey-darker);
        }
      }
    }

    .close-section {
      color: var(--grey-darker);
    }
  }


  .black-square {
    color: var(--grey-darkest) !important;
  }

  .account-container .desktop .vehicle-tab {
    border-left: 4px solid #6D717A;
  }

  .account-container .mobile .vehicle-tab {
    .tab-top {
      padding-top: 25px;
    }

    .tab-top,
    .tab-bottom {
      padding-left: 25px;
    }
  }


  /********************
    CHECKOUT PAGE
    ********************/

  @media (max-width: 768px) {
    app-checkout {
      mdb-card-body {
        .btn {
          width: 100%;
        }
      }
    }
  }

  app-checkout {
    .checkout .edit-remove-links {
      display: none;
    }

    .coverage-start,
    .items-in-cart {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

  }

  .modal-content i.fa-shopping-cart {
    display: none;
  }

  /********************
    NAVBAR
    ********************/

  .logo {
    height: 40px;
    margin-top: -4px;

    @include breakpoint(xs) {
      margin-top: 4px;
      height: 30px;
      margin-left: 7px;
    }
  }

  .navbar-brand {
    padding: 0;

    @include breakpoint(xs) {
      margin-top: 0;
    }
  }


  navlinks {
    padding-top: 3px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media (max-width: 787px) {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .navbar {
    background-color: var(--secondary-color-med);
  }

  .navbar .dropdown-toggle {
    font-size: 14px;
    line-height: 20px;
  }

  .navbar a.nav-link,
  .navbar .dropdown-toggle {

    font-weight: 400;
    font-size: 17px !important;
    line-height: 20px;
    cursor: pointer;
    color: var(--font-white) !important;
  }

  .navbar .nav-item {
    margin-left: 30px !important;

    @media (max-width: 992px) {
      margin-left: 15px !important;
    }

    .nav-link {
      padding: 0 !important;

      i.fas {
        padding: 0 0 3px 0 !important;
      }
    }
  }

  .navbar a.nav-link:hover,
  .navbar .dropdown-toggle:hover {
    color: var(--grey-med) !important;
  }

  // @media (max-width: 992px) {
  //   .navbar .nav-item {
  //     .nav-link {
  //       padding: 0 0 3px 0 !important;
  //     }
  //   }
  // }

  .navbar .nav-item.active {
    border-bottom: 2px solid var(--font-white) !important;
  }

  .navbar.scrolling-navbar {

    // height: 62px;
    @include breakpoint(xs) {
      // height: 60px;
    }
  }

  .navbar,
  .sideNavLeft {
    .vehicle-service-protection {
      display: none;
    }

    .how-vsp-works {
      display: none;
    }

    .myaccount.show {
      border-bottom: none;
    }
  }

  .myaccount .accordion {
    .card-header {
      background-color: transparent !important;

      a {
        border-top: none !important;

        h5 {
          color: #333 !important;
        }
      }
    }
  }


  .sidenav {
    a:first-child {
      border-top: 1px solid #dadada;
    }

    .brand {
      padding: 0 100px 25px;
    }
  }

  .navbar {
    .shopping-cart-icon {
      padding: 3px 0 0 0;

      a {
        position: relative;
        display: block;

        @include breakpoint(xs) {
          padding-top: 10px;
        }

        &.waves-effect {
          overflow: visible;
        }

        mdb-icon {
          display: block;
        }
      }
    }
  }

  .icon-bar .call {
    background-color: var(--primary-color-darker) !important;
  }

  .navbar {
    .nav-link {
      overflow: visible !important;
      padding: 0;

      .fa-bars {
        padding-top: 10px;
      }
    }

    ;

    .call {
      a.phone_number.nav-link {
        color: var(--primary-color-darker) !important;
      }
    }

    .call.desktop {
      a.phone_number {
        position: relative;
        overflow: visible;

        i {
          &:before {
            content: "";
          }
        }
      }
    }

    .call.box {
      border: 1px solid var(--font-white);
      position: relative;
      margin-top: 3px !important;

      a.phone_number {
        float: right;
        padding: 3px 3px 3px 3px !important;
        color: var(--font-white) !important;

        span {
          margin-right: 3px;
          letter-spacing: 1px;
          font-size: 14px;
          font-weight: var(--font-bold);
        }

        i {
          position: relative;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 16px;
          height: 16px;
          transform: scaleX(-1);
        }
      }
    }
  }

  .shopping-cart-icon .badge-primary {
    position: absolute !important;
    color: var(--grey-darker);
    background-color: var(--primary-color-darker);
    margin-top: -30px;
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    .navbar {
      .nav-item {
        padding: 0 0 3px 0 !important;
        margin-top: 8px !important;
      }
    }
  }

  /********************
    MAIN LANDING PAGE
    ********************/

  .whats-not-covered {
    app-component-text {
      @include breakpoint(xs) {
        .col-12 {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .content-feature {
    &.content-feature-top-padding {
      padding-top: 10px !important;
    }

    @include breakpoint(xs) {
      padding: 0px !important;
      padding-top: 24px !important;

      &.content-feature-top-padding {
        padding-top: 0 !important;
      }
    }
  }

  hr {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lamborghini-rates {
    padding-top: 15px;

  }

  .coverage-options-component {
    padding-bottom: 30px;
    background-color: var(--grey-darker);
    color: var(--font-white) !important;

    h2 {
      color: var(--font-white) !important;
    }
  }

  app-coverage-options {
    padding-bottom: 0 !important;
    background-color: var(--grey-darker);
  }

  .select-your-plan {
    background-color: #F9F9F9;

  }

  .lamborghini-hero {
    margin-bottom: 0 !important;

    .content-feature-top-padding {
      max-height: 900px !important;
      height: 632px;

      @media (max-width: 767px) {
        background-position: center bottom !important;
      }
    }

    @media (max-width: 767px) {
      app-component-title .col-12 {
        padding: 20px 10px 10px !important;
      }

      app-component-text .col-12 {
        display: none;
      }
    }

    .hero-sub-title {
      font-weight: var(--font-regular);
      display: block;
      margin-top: -8px;
    }
  }

  .available-coverage-plans {
    h3 {
      font-weight: var(--font-bold) !important;
      color: #000000;
    }
  }

  @media (max-width: 767px) {
    app-component-video #media {
      width: 100% !important;
    }

    .icon-group {
      margin-bottom: 24px;
    }
  }

  h2.embeddedservice-chatheader_chatheader {
    font-size: 20px !important;
  }

  @media (max-width: 767px) {
    .content-feature .mobile-margin {
      padding-top: 0 !important;
    }
  }

  @media (max-width: 767px) {
    .my-lamborghini-rates {
      .content-feature {
        padding-bottom: 30px !important;

        .title-component>div {
          text-align: center !important;

          H1 {
            text-transform: none;
          }
        }
      }
    }
  }


  .confirmation {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--grey-darker);
    }
  }

  app-footer-questions {
    .row {
      @media (max-width: 769px) {
        padding: 0 32px;

        .subfooter-item {
          padding: 12px 0;
        }
      }
    }
  }

  .disclaimer p {
    font-size: 14px !important;
    font-weight: var(--font-light);
  }

  /****************
    * Customize Plan
    *****************/

  // @media only screen and (min-width: 769px) {
  //   .my-pricing-background {
  //     .container {
  //       h1 {
  //         max-width: 75%;
  //       }
  //     }
  //   }
  // }

  .price-cost {
    font-size: 30px !important;
    font-weight: 800 !important;

    .price-cost-asterisk {
      font-size: 18px !important;
    }
  }

  // .price-plan {
  //   .excludes-tax {
  //     color: var(--grey-darker) !important;
  //     font-size: 14px !important;
  //     line-height: 20px !important;
  //     font-weight: 600;
  //   }
  // }

  .vehicle-info-container {

    .vehicle-information,
    strong {
      font-weight: normal;
    }
  }

  @media only screen and (min-width: 769px) {
    .mini-main-hero .vehicle-info-container {
      justify-content: left;
    }
  }

  .vehicle-component .vehicle-information {
    line-height: 1.2;
    font-size: 18px !important;
    text-transform: none;

  }

  .plan-select {
    font-size: 18px;
    font-weight: normal;
    color: var(--primary-color-darkest) !important;
    background-color: transparent;
    border: 1px solid var(--primary-color-darkest);
    letter-spacing: 1px;
    margin: 0;

    &:first-child {
      border-left: 1px solid var(--primary-color-darkest) !important;
    }

    &.active {
      border: none !important;
      border-right: 4px solid white !important;
      border-left: 4px solid white !important;
      background-color: var(--grey-darker);

      &:first-child {
        margin-left: 0 !important;
        border-left: none !important;
      }

      &:last-child {
        margin-right: 0 !important;
        border-right: none !important;
      }
    }
  }

  .plan-select-border {
    border: none;
    height: 3px;
    margin: 0;
    background-color: var(--grey-darker);
    position: relative;
  }

  .cr-red {
    background-color: var(--primary-color-darker) !important;
  }

  .customize-container {
    .md-form {
      background-color: white;
    }
  }

  .parts-covered-background {
    .parts {
      .fa-check {
        color: var(--primary-color-dark);
      }
    }
  }

  .customize-container,
  .coverage-overview {
    background: var(--grey-lite);
  }

  .customize-payments {
    .md-form {
      box-shadow: none;
    }
  }
}


// @media only screen and (max-width: 641px) {
//   app-component-title .h1 {
//     text-align: center;
//   }
// }

app-checkout-registration-info-state-change-modal {
  .modal-body {
    padding: 32px !important;
  }

  .primary-btn {
    width: 240px !important;

  }

  @media only screen and (max-width: 768px) {
    .primary-btn {
      width: 190px !important;
    }

    .modal-body {
      padding: 16px !important;
    }
  }
}

.cookie-acceptance-modal {
  background-color: var(--grey-darker);
}

.prismic-grid {
  .full-img-container {
    @media only screen and (max-width: 768px) {
      padding: 0 !important
    }
  }
}

.content-feature.countdown-timer {
  padding-top: 0 !important;
}

b,
strong {
  font-weight: var(--font-bold) !important;
}